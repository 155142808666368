import { FlowNode, FlowRoot } from '@models/flow.model';
import { Chain } from 'hooks/models/hooks.model';

export const constructChain = (form: FlowRoot, sQId: string): Chain[] => {
  const questions: Chain[] = [];
  let q = form[sQId] as FlowNode;
  let next: string;
  while (q) {
    questions.push({
      id: q.id,
      next: q.next || '',
    });
    next = q.next;
    q = form[next] as FlowNode;
  }
  return questions;
};
