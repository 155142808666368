import { WS_TIER, WS_TIER_MAP } from '../constants/wealthsimple.constants';

export const getClientTier = (amount: number | undefined): WS_TIER => {
  const balance = amount || 0;

  switch (true) {
    case balance >= WS_TIER_MAP[WS_TIER.PREMIUM] && balance < WS_TIER_MAP[WS_TIER.GENERATION]:
      return WS_TIER.PREMIUM;
    case balance >= WS_TIER_MAP[WS_TIER.GENERATION]:
      return WS_TIER.GENERATION;
    case balance < WS_TIER_MAP[WS_TIER.PREMIUM] && balance > 0:
      return WS_TIER.CORE;
    default:
      return WS_TIER.UNKNOWN;
  }
};
