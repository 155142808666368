export enum WS_TIER {
  CORE = 'core',
  PREMIUM = 'premium',
  GENERATION = 'generation',
  UNKNOWN = 'unknown',
}

export const WS_TIER_MAP: Record<WS_TIER, number> = {
  [WS_TIER.CORE]: 1,
  [WS_TIER.PREMIUM]: 100000,
  [WS_TIER.GENERATION]: 500000,
  [WS_TIER.UNKNOWN]: 0,
};
