import { NAMESPACE } from '@models/enums';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import Styled from './TierRebate.styled';
import { useMemo } from 'react';
import { formatPercent } from 'utils';
import { useBestRateRebateQuery } from 'hooks';
import { WS_TIER, getClientTier } from '@/lib/partner';

const TierRebate = () => {
  const { data, isLoading } = useBestRateRebateQuery();
  const { t } = useTranslation(NAMESPACE.PARTNER);
  const { control } = useFormContext();
  const [newMoneyToWealthsimple, currentWealthsimpleBalance] = useWatch({
    control,
    name: ['newMoneyToWealthsimple', 'currentWealthsimpleBalance'],
  });

  const tier = getClientTier(currentWealthsimpleBalance);

  const rebate = useMemo(() => {
    if (!newMoneyToWealthsimple) return 0;

    const rate = Math.floor(newMoneyToWealthsimple / Number(data?.newMoney?.every)) * Number(data?.newMoney?.discount);
    return rate;
  }, [data?.newMoney?.discount, data?.newMoney?.every, newMoneyToWealthsimple]);

  return (
    <Styled.Wrapper>
      <Styled.Title>
        {t(tier === WS_TIER.UNKNOWN ? 'WEALTHSIMPLE_TIER_UNKNOWN' : 'WEALTHSIMPLE_TRANSFER', {
          tier: t(`WEALTHSIMPLE_TIER_${tier.toUpperCase()}`),
        })}
      </Styled.Title>
      <Styled.TierCard>
        {isLoading ? (
          <>
            <Styled.SkeletonRebate />
            <Styled.SkeletonRebateText />
          </>
        ) : (
          <>
            <Styled.Rebate data-testid="tier-rebate-percent">
              {formatPercent(rebate, { allowZero: true, minimumFractionDigits: 2 })}
            </Styled.Rebate>
            <Styled.RebateText data-testid="tier-rebate-text">{t('WEALTHSIMPLE_TRANSFER_REBATE')}</Styled.RebateText>
          </>
        )}
      </Styled.TierCard>
    </Styled.Wrapper>
  );
};

export default TierRebate;
