export enum LONG_APP_ROUTES {
  ASSETS = 'assets',
  INCOMES = 'incomes',
  APPLICANT_INFO = 'applicant-info',
  PROPERTY = 'subject-property',
  REVIEW = 'review',
  SUMMARY = 'summary',
  RATES = 'rates',
  SUBMITTED = 'submitted',
  ADDITIONAL_BORROWERS = 'additional-borrowers',
  BORROWERS_INFO = 'borrower-info',
}
