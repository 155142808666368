import { NAMESPACE } from '@models/enums';
import { useTranslation } from 'next-i18next';
import Styled from './TierOption.styled';
import { formatCurrency } from 'utils';
import { WS_TIER, WS_TIER_MAP } from '@/lib/partner';

interface TierOptionProps {
  tier: WS_TIER;
}

const TierOption = ({ tier }: TierOptionProps) => {
  const { t } = useTranslation(NAMESPACE.DEFAULT);

  return (
    <Styled.Wrapper>
      <Styled.Label>{t(`FLOW_WS_CLIENT_${tier.toUpperCase()}`)}</Styled.Label>
      {tier !== WS_TIER.UNKNOWN && (
        <Styled.AssetText tier={tier}>
          {t('FLOW_WS_CLIENT_ASSETS', { amount: formatCurrency(WS_TIER_MAP[tier].toString(), { minimumFractionDigits: 0 }) })}
        </Styled.AssetText>
      )}
    </Styled.Wrapper>
  );
};

export default TierOption;
