import styled from 'styled-components';

const StyledPolicy = {
  PolicyWrapper: styled.div`
    display: grid;
    grid-template-columns: 32px 1fr;
    align-items: center;
    padding: 16px;
    background-color: ${({ theme }) => theme.background.secondary};
    border-radius: 12px;
  `,
  PolicyText: styled.p`
    font-family: var(--font-futura);
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.text.secondary};
  `,
  PolicyAnchor: styled.a`
    color: ${({ theme }) => theme.text.primary};
    text-decoration: underline;
  `,
};

export default StyledPolicy;
