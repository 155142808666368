import { StepStatus } from '@components/StepIndicator';
import { CUSTOMER_PORTAL_ROUTES, ROUTES } from '@models/enums';
import { Chain } from 'hooks/models/hooks.model';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

export const useConstructSteps = (chain: Chain[], currentIndex: number) => {
  const { asPath } = useRouter();
  return useMemo(
    () => [
      {
        label: 'STEP_INDICATOR_PROPERTY',
        status: StepStatus.IN_PROGRESS,
        current: asPath === ROUTES.ROOT || asPath.endsWith(CUSTOMER_PORTAL_ROUTES.BEGIN),
        currentProgress: currentIndex || 0,
        totalProgress: chain?.length || 0,
      },
      {
        label: 'STEP_INDICATOR_APPLICANT',
        status: StepStatus.INCOMPLETE,
        current: false,
        currentProgress: 0,
        totalProgress: 18, // arbitrary value to not extend the progress bar too far, long app will add the correct count
      },
      // arbitrary values for the short app and are corrected in the long app flow
      {
        label: 'STEP_INDICATOR_REVIEW',
        status: StepStatus.INCOMPLETE,
        current: false,
        totalProgress: 1,
        currentProgress: 1,
      },
    ],
    [asPath, chain?.length, currentIndex]
  );
};
