import { API_STATUS } from '@models/enums/apiStatus.enum';
import { FlowNode, FlowRoot } from '@models/flow.model';
import { FlowState } from '@models/flowState.model';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { constructChain } from './helpers/chain.helper';
import { IFlowState } from './models/hooks.model';

type FlowHook = [state: IFlowState, updateState: (obj: any) => void, options: { currentQuestion: string | FlowNode | undefined }];

const useFlow = (form: FlowRoot, appId?: string): FlowHook => {
  const router = useRouter();
  const mountRef = useRef(false);
  const { applicationId, borrowerId } = router.query;
  const rootNode = form[form.root as string] as FlowNode;
  const initialState: IFlowState = {
    prevActiveQIdx: -1,
    currActiveQIdx: 0,
    lastVisitedQIdx: 0,
    apiState: API_STATUS.INIT,
    nextQId: undefined,
    chain: constructChain(form, rootNode.id),
  };
  const [state, setState] = useState(initialState);

  const moveToQuestion = useCallback(() => {
    const currQId = state.chain[state.currActiveQIdx].id;
    const currQIdx = state.chain.findIndex((c) => c.id === currQId);
    const moveToIdIdx = currQIdx + 1;
    const moveToQ = state.chain[moveToIdIdx];

    if (moveToQ) {
      // 1. assign pre node for next question
      // 2. flag current question as visited
      setState((prev) => ({
        ...prev,
        prevActiveQIdx: currQIdx,
        currActiveQIdx: moveToIdIdx,
        lastVisitedQIdx: moveToIdIdx > prev.lastVisitedQIdx ? moveToIdIdx : prev.lastVisitedQIdx,
        apiState: API_STATUS.INIT,
      }));
    }
  }, [state.chain, state.currActiveQIdx]);

  const navigateTo = useCallback(
    (nextSection: string): void => {
      const url = nextSection.replace(':applicationId', applicationId as string).replace(':borrowerId', (borrowerId ?? appId) as string);
      router.push(url);
    },
    [applicationId, appId, borrowerId, router]
  );

  const updateChain = useCallback((): void => {
    if (state.nextQId === FlowState.NEXTSECTION) {
      navigateTo(form.nextSecUrl as string);
      return;
    }

    const question = state.chain[state.currActiveQIdx];

    switch (question.next) {
      case FlowState.SEPARATE:
      case FlowState.DYNAMIC:
        setState((prev) => {
          const { currActiveQIdx, chain } = prev;
          const nextQIdx = currActiveQIdx + 1;
          const newChain = chain.slice(0, nextQIdx).concat(constructChain(form, state.nextQId as FlowState));
          return {
            ...prev,
            prevActiveQIdx: currActiveQIdx,
            currActiveQIdx: nextQIdx,
            lastVisitedQIdx: nextQIdx,
            chain: newChain,
            apiState: API_STATUS.INIT,
          };
        });
        break;
      case FlowState.NEXTSECTION:
        navigateTo(form.nextSecUrl as string);
        break;
      default:
        moveToQuestion();
        break;
    }
  }, [moveToQuestion, navigateTo, state.chain, state.currActiveQIdx, form, state.nextQId]);

  const updateState = useCallback((obj: Partial<IFlowState>) => {
    setState((prev) => ({
      ...prev,
      ...obj,
    }));
  }, []);

  /**
   * bring user to last completed section only when component mounted, chain is completely built and has a hash
   */
  useEffect(() => {
    const hash = router.asPath?.includes('#') ? router.asPath.split('#')[1] : '';
    if ((!hash && !form) || mountRef.current) return;
    const fragment = hash.replace('#', '');
    const currentQuestionIndex = state.chain.findIndex(({ id }) => id === fragment);
    if (currentQuestionIndex !== -1) {
      updateState({
        currActiveQIdx: currentQuestionIndex,
        prevActiveQIdx: currentQuestionIndex - 1,
        lastVisitedQIdx: currentQuestionIndex,
      });
      mountRef.current = true;
    }
  }, [router.pathname, state.chain, router.asPath, form, updateState]);

  useEffect(() => {
    if (state.apiState === API_STATUS.LOADED) {
      updateChain();
    }
  }, [state.apiState, updateChain]);

  const currentQuestion = useMemo(() => {
    const questionId = state?.chain?.[state?.currActiveQIdx]?.id;
    if (!questionId) return undefined;
    return form[questionId];
  }, [state?.chain, state?.currActiveQIdx, form]);

  return [state, updateState, { currentQuestion }];
};

export default useFlow;
