import styled, { keyframes } from 'styled-components';

const loading = keyframes`
0% { background-color: hsl(0, 0%, 90%); }
100% { background-color: hsl(0, 0%, 97%); }
`;

const StyledTierRebate = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 40px;
  `,
  Title: styled.h2`
    color: ${({ theme }) => theme.text.primary};
    font-family: var(--font-futura);
    font-weight: 450;
    font-size: 18px;
    line-height: 23px;
  `,
  TierCard: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 32px;
    border: 1px solid ${({ theme }) => theme.border.primary};
    background-color: ${({ theme }) => theme.background.primary};
    border-radius: 8px;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      padding: 24px;
    }
  `,
  Rebate: styled.h2`
    color: ${({ theme }) => theme.text.primary};
    font-family: var(--font-tiempos);
    font-weight: 400;
    font-size: 36px;
    line-height: 41px;
    letter-spacing: 0.2px;
    min-height: 41px;
  `,
  SkeletonRebate: styled.div`
    width: 116px;
    height: 41px;
    border-radius: 8px;
    animation: ${loading} 1.5s infinite alternate;
  `,
  SkeletonRebateText: styled.div`
    width: 131px;
    height: 17px;
    border-radius: 8px;
    animation: ${loading} 1.5s infinite alternate;
  `,
  RebateText: styled.p`
    color: ${({ theme }) => theme.text.primary};
    font-family: var(--font-futura);
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    letter-spacing: 1.28px;
  `,
};

export default StyledTierRebate;
