import { WS_TIER } from '@/lib/partner';
import styled from 'styled-components';

const StyledYourTier = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 12px;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      flex-direction: column;
      align-items: flex-start;
    }
  `,
  Label: styled.p`
    color: ${({ theme }) => theme.text.primary};
    font-family: var(--font-futura);
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      font-size: 18px;
      line-height: 22px;
    }
  `,
  AssetText: styled.span<{ tier: WS_TIER }>`
    padding: 8px 16px;
    border-radius: 100px;

    background-color: ${({ theme, tier }) => (tier === WS_TIER.GENERATION ? theme.background.accent : theme.background.secondary)};

    color: ${({ theme, tier }) => (tier === WS_TIER.GENERATION ? theme.text.invert : theme.text.primary)};
    font-family: var(--font-futura);
    font-weight: 450;
    font-size: 14px;
    line-height: 17px;
  `,
};

export default StyledYourTier;
