import { faLockKeyhole } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NAMESPACE } from '@models/enums';
import { Trans, useTranslation } from 'next-i18next';
import Styled from './Policy.styled';

const Policy = () => {
  const { t } = useTranslation(NAMESPACE.DEFAULT);
  return (
    <Styled.PolicyWrapper>
      <FontAwesomeIcon icon={faLockKeyhole} size="lg" />
      <Styled.PolicyText>
        <Trans
          i18nKey="REGISTER_POLICY"
          t={t}
          components={{ anchor: <Styled.PolicyAnchor href={t('PRIVACY_POLICY_URL')} target="_blank" /> }}
        />
      </Styled.PolicyText>
    </Styled.PolicyWrapper>
  );
};

export default Policy;
